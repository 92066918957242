import {useForm} from 'react-hook-form';
import FormField from '../../../components/Fields/FormField';
import {Headline3, Headline4} from '../../../components/Headlines/Headlines';
import {Body, bodyTextStyles} from '../../../components/Texts/Texts';
import styled from 'styled-components';
import {Link, useActionData, useLocation, useNavigation, useRouteLoaderData, useSubmit} from 'react-router-dom';
import {FormRow, FormRowHidden, FormRowItem} from '../../../components/Fields/FormRows';
import {useAddressFromPostalCode} from '../../../hooks/useAddressFromPostalCode';
import React, {useEffect, useRef} from 'react';
import isEqual from 'lodash.isequal';
import omit from 'lodash.omit'
import {useAccount} from '../../../hooks/useHasAccount';
import {useDebouncedCallback} from 'use-debounce';

const StyledDetails = styled.section``;

const Heading = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledLink = styled(Link)`
    ${bodyTextStyles};
    
    &:hover {
        text-decoration: none;
    }
`;

const UserExists = styled(Body)`
    margin-bottom: 40px;
`;

const StyledForm = styled.form`
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    padding: 30px 60px 30px 40px;
    margin-top: 20px;
    
    @media screen and (max-width: 1024px) {
        padding: 30px 50px 30px 20px
    }
    
    @media screen and (max-width: 768px) {
        padding: 30px 30px 30px 20px;
    }
`;

const Email = styled.div`
    margin-bottom: 50px;

    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
    }
`;

const ExtraFields = styled.div`
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid var(--color-primary-10);
    
    ${Headline4} { margin-bottom: 20px; }
`;

const StyledBody = styled(Body)`
    ${({$error}) => $error && `
        color: var(--color-secondary);
        font-weight: 700;
    `};
`;

export default function GuestUser() {
    const {cartData} = useRouteLoaderData("orderLayout");
    const location = useLocation();

    // Check if invoiceAddres and deliveryAddress are the same
    let deliveryAddressObject = cartData?.deliveryAddress;
    let invoiceAddressObject = cartData?.invoiceAddress;
    let deliverySameAsInvoice = isEqual(omit(deliveryAddressObject, ['id', 'type']), omit(invoiceAddressObject, ['id', 'type']));

    const isDeliveryTheSameAsInvoice = (data) => {
        return deliverySameAsInvoice ? "" : (data ?? "");
    }

    const { register, watch, formState: { errors }, setError, clearErrors, handleSubmit, getValues, setFocus, setValue } = useForm({
        defaultValues: {
            deliveryAddress: cartData?.deliveryAddress?.id ?? "",
            invoiceAddress: cartData?.invoiceAddress?.id ?? "",
            email: cartData?.email ?? "",
            salutation: cartData?.invoiceAddress?.salutation ?? "De heer",
            firstName: cartData?.invoiceAddress?.firstName ?? "",
            prefix: cartData?.invoiceAddress?.prefix ?? "",
            lastName: cartData?.invoiceAddress?.lastName ?? "",
            postcode: cartData?.invoiceAddress?.postcode ?? "",
            number: cartData?.invoiceAddress?.number ?? "",
            postbus: cartData?.invoiceAddress?.postbus ?? "",
            addressLine1: cartData?.invoiceAddress?.addressLine1 ?? "",
            city: cartData?.invoiceAddress?.city ?? "",
            country: cartData?.invoiceAddress?.country ?? "",
            phone: cartData?.invoiceAddress?.phone ?? "",
            deliverySalutation: deliverySameAsInvoice ? "De heer" : (cartData?.deliveryAddress?.salutation ?? "De heer"),
            deliveryFirstName: isDeliveryTheSameAsInvoice(cartData?.deliveryAddress?.firstName),
            deliveryPrefix: isDeliveryTheSameAsInvoice(cartData?.deliveryAddress?.prefix),
            deliveryLastName: isDeliveryTheSameAsInvoice(cartData?.deliveryAddress?.lastName),
            deliveryPostcode: isDeliveryTheSameAsInvoice(cartData?.deliveryAddress?.postcode),
            deliveryNumber: isDeliveryTheSameAsInvoice(cartData?.deliveryAddress?.number),
            deliveryPostbus: isDeliveryTheSameAsInvoice(cartData?.deliveryAddress?.postbus),
            deliveryAddressLine1: isDeliveryTheSameAsInvoice(cartData?.deliveryAddress?.addressLine1),
            deliveryCity: isDeliveryTheSameAsInvoice(cartData?.deliveryAddress?.city),
            deliveryCountry: isDeliveryTheSameAsInvoice(cartData?.deliveryAddress?.country),
            deliveryPhone: isDeliveryTheSameAsInvoice(cartData?.deliveryAddress?.phone),
            addressCheck: deliverySameAsInvoice ? true : false,
            shop: cartData?.shop ?? "",
            deliveryType: cartData?.deliveryType,
            intent: "guestUser",
        }
    });
    FormField.defaultProps = {errors: errors, register: register};

    const watchEmail = watch('email', null);

    // Use action data for setting errors with the response
    let actionData = useActionData();
    const navigation = useNavigation();
    let prevState = useRef('idle');

    useEffect(() => {
        if(prevState.current === 'loading' && navigation.state === 'idle') {
            if(actionData) {
                let err = JSON.parse(actionData);

                // Errorcode 11 = already exists
                if(err?.errorcode === 11) {
                    setError('email', { type: 'required', message: 'Dit e-mailadres wordt al gebruikt'});
                    setFocus('email');
                }
                if(err?.error) {
                    setError(err?.error, { type: 'required', message: 'Dit veld is verplicht'});
                }
            }
        }

        prevState.current = navigation.state;
    }, [errors, navigation.state, setFocus, actionData, setError])

    const submit = useSubmit();
    const onSubmit = (data) => {
        clearErrors();

        if(cartData?.deliveryAddress?.id || cartData?.invoiceAddress?.id) {
            submit(data, { method: 'put', action: '/bestellen/jouw-gegevens' })
        } else {
            submit(data, { method: 'post', action: '/bestellen/jouw-gegevens' })
        }
    };

    // Use addressFromPostalCode hook for usage of PostcodeAPI
    let defaultAddress;
    if(cartData?.invoiceAddress?.addressLine1) { defaultAddress = `${cartData?.invoiceAddress?.addressLine1} ${cartData?.invoiceAddress?.number}${cartData?.invoiceAddress?.postbus ?? ""}, ${cartData?.invoiceAddress?.city}`; }

    let defaultDeliveryAddress;
    if(!deliverySameAsInvoice && cartData?.deliveryAddress?.addressLine1) { defaultDeliveryAddress = `${cartData?.deliveryAddress?.addressLine1} ${cartData?.deliveryAddress?.number}${cartData?.deliveryAddress?.postbus ?? ""}, ${cartData?.deliveryAddress?.city}`; }

    const {displayAddressFromPostalCode, displayDeliveryAddressFromPostalCode, handleAddress, handleDeliveryAddress} = useAddressFromPostalCode(getValues, setValue, defaultAddress, defaultDeliveryAddress);
    const debouncedHandleAddress = useDebouncedCallback(handleAddress, 400);
    const debouncedHandleDeliveryAddress = useDebouncedCallback(handleDeliveryAddress, 400);

    const watchAddressCheck = watch("addressCheck", deliverySameAsInvoice ? true : false);
    const watchAccountCheck = watch("accountCheck", false);

    // Use hasAccount hook to check if user has account
    const {hasAccount, handleHasAccount} = useAccount();

    return (
        <StyledDetails>
            <Heading>
                <Headline3>Jouw gegevens</Headline3>
                <StyledLink to="/login" state={{ from: location }}>Inloggen</StyledLink>
            </Heading>
            <StyledForm id="detailsForm" onSubmit={handleSubmit(onSubmit)}>

                <FormRow $spacing="custom" $space={errors?.email || hasAccount ? '10px' : '50px'} $ignore>
                    <FormField type="email" title="E-mailadres" name="email" onBlur={(e) => e?.target?.value?.length >= 3 ? handleHasAccount(e.target.value) : null} tooltip="Voer je e-mailadres in om je bestelling te kunnen volgen en om updates te ontvangen over je account." required={true}/>
                </FormRow>

                {hasAccount && <UserExists>Er is al een account met dit e-mailadres. Wil je hiermee <StyledLink to="/login" state={{ from: location, "email": watchEmail }}>inloggen</StyledLink>?</UserExists>}
                {errors?.email && <UserExists>Je kunt met dit e-mailadres <StyledLink to="/login" state={{ from: location, "email": watchEmail }}>inloggen</StyledLink>. Om een nieuw acount aan te maken kun je een ander e-mailadres gebruiken.</UserExists>}

                <FormRow $spacing="large" $ignore>
                    <FormField
                        type="radio"
                        title="Aanhef"
                        name="salutation"
                        breakPoint={1024}
                        options={[
                            {
                                "id": "salutationChoice1",
                                "value": "De heer",
                                "title": "De heer"
                            },
                            {
                                "id": "salutationChoice2",
                                "value": "Mevrouw",
                                "title": "Mevrouw"
                            },
                            {
                                "id": "salutationChoice3",
                                "value": "-",
                                "title": "Liever geen van beide"
                            }
                        ]}
                    />
                </FormRow>

                <FormRow $spacing="small" $fullRow>
                    <FormRowItem>
                        <FormField type="text" title="Voornaam" name="firstName" required={true}/>
                    </FormRowItem>
                    <FormRowItem $small>
                        <FormField type="text" title="Tussenv." name="prefix" maxLength={10} />
                    </FormRowItem>
                    <FormRowItem>
                        <FormField type="text" title="Achternaam" name="lastName" required={true}/>
                    </FormRowItem>
                </FormRow>

                <FormRow $spacing="small">
                    <FormRowItem>
                        <FormField type="text" title="Postcode" name="postcode" onChange={debouncedHandleAddress ?? null} required={true} />
                    </FormRowItem>
                    <FormRowItem $small>
                        <FormField type="text" title="Huisnr." name="number" onChange={debouncedHandleAddress ?? null} required={true} />
                    </FormRowItem>
                    <FormRowItem $small>
                        <FormField type="text" title="Toev." name="postbus" onChange={debouncedHandleAddress ?? null} />
                    </FormRowItem>
                </FormRow>

                <FormRow $spacing="large" $ignore>
                    <Body $bold $small>Adres</Body>
                    <StyledBody
                        $error={(displayAddressFromPostalCode === "Het adres kon niet gevonden worden. Controleer of je postcode en huisnummer kloppen.") || (Boolean(errors?.['addressLine1']) || Boolean(errors?.['city']))}
                    >
                        {(Boolean(errors?.['addressLine1']) || Boolean(errors?.['city'])) ? (
                            "Het adres kon niet gevonden worden. Controleer of je postcode en huisnummer kloppen."
                        ) : displayAddressFromPostalCode ? (
                            displayAddressFromPostalCode
                        ) : "Geef een postcode en huisnummer op"}
                    </StyledBody>
                </FormRow>

                {/* Hidden fields so it's included in FormData */}
                <FormRowHidden>
                    <FormField type="text" title="Adres" name="addressLine1" required={true} />
                    <FormField type="text" title="Plaats" name="city" required={true} />
                    <FormField type="text" name="country" />
                    <FormField type="text" name="intent" />
                </FormRowHidden>

                <FormRow $spacing="medium">
                    <FormRowItem>
                        <FormField type="tel" title="Telefoonnummer" name="phone" required={false}/>
                    </FormRowItem>
                </FormRow>

                <FormRow $spacing="small" $ignore>
                    <FormField type="checkbox" title="Account aanmaken" tooltip="Maak een account aan om sneller te kunnen bestellen en om toegang te krijgen tot je bestelgeschiedenis." name="accountCheck" />
                </FormRow>

                {(cartData?.deliveryType !== 1 && cartData?.deliveryType !== 2) &&
                    <FormRow $spacing="small" $ignore>
                        <FormField type="checkbox" title="Bezorgadres is hetzelfde als bovenstaand factuuradres" name="addressCheck" />
                    </FormRow>
                }

                {watchAccountCheck &&
                    <ExtraFields>
                        <Headline4>Account aanmaken</Headline4>
                        <FormRow $spacing="small" $breakpoint="1280px">
                            <FormRowItem>
                                <FormField type="password" name="password" autoComplete="new-password" title="Nieuw wachtwoord" placeholder="Nieuw wachtwoord" watch={watch} required={true} />
                            </FormRowItem>
                            <FormRowItem>
                                <FormField type="password" name="password-repeat" autoComplete="new-password" title="Herhaal nieuw wachtwoord" placeholder="Herhaal nieuw wachtwoord" watch={watch} required={true} />
                            </FormRowItem>
                        </FormRow>
                        <Email>
                            <FormRow $ignore>
                                <FormField type="checkbox" title="Schrijf je in voor de nieuwsbrief." name="newsletter" />
                            </FormRow>
                        </Email>
                    </ExtraFields>
                }

                {(!watchAddressCheck && cartData?.deliveryType !== 2) &&
                    <ExtraFields>
                        <Headline4>Bezorgadres</Headline4>
                        <FormRow $spacing="large" $ignore>
                            <FormField
                                type="radio"
                                title="Aanhef"
                                name="deliverySalutation"
                                breakPoint={1024}
                                options={[
                                    {
                                        "id": "deliverySalutationChoice1",
                                        "value": "De heer",
                                        "title": "De heer"
                                    },
                                    {
                                        "id": "deliverySalutationChoice2",
                                        "value": "Mevrouw",
                                        "title": "Mevrouw"
                                    },
                                    {
                                        "id": "deliverySalutationChoice3",
                                        "value": "-",
                                        "title": "Liever geen van beide"
                                    }
                                ]}
                            />
                        </FormRow>

                        <FormRow $spacing="small" $fullRow>
                            <FormRowItem>
                                <FormField type="text" title="Voornaam" name="deliveryFirstName" required={true}/>
                            </FormRowItem>
                            <FormRowItem $small>
                                <FormField type="text" title="Tussenv." name="deliveryPrefix" small={true}/>
                            </FormRowItem>
                            <FormRowItem>
                                <FormField type="text" title="Achternaam" name="deliveryLastName" required={true}/>
                            </FormRowItem>
                        </FormRow>

                        <FormRow $spacing="small">
                            <FormRowItem>
                                <FormField type="text" title="Postcode" name="deliveryPostcode" onChange={debouncedHandleDeliveryAddress ?? null} required={true} />
                            </FormRowItem>
                            <FormRowItem $small>
                                <FormField type="text" title="Huisnr." name="deliveryNumber" onChange={debouncedHandleDeliveryAddress ?? null} required={true} />
                            </FormRowItem>
                            <FormRowItem $small>
                                <FormField type="text" title="Toev." name="deliveryPostbus" onChange={debouncedHandleDeliveryAddress ?? null} />
                            </FormRowItem>
                        </FormRow>

                        <FormRow $spacing="large" $ignore>
                            <Body $bold $small>Adres</Body>
                            <StyledBody
                                $error={(displayDeliveryAddressFromPostalCode === "Het adres kon niet gevonden worden. Controleer of je postcode en huisnummer kloppen.") || (Boolean(errors?.['deliveryAddressLine1']) || Boolean(errors?.['deliveryCity']))}
                            >
                                {(Boolean(errors?.['deliveryAddressLine1']) || Boolean(errors?.['deliveryCity'])) ? (
                                    "Het adres kon niet gevonden worden. Controleer of je postcode en huisnummer kloppen."
                                ) : displayDeliveryAddressFromPostalCode ? (
                                    displayDeliveryAddressFromPostalCode
                                ) : "Geef een postcode en huisnummer op"}
                            </StyledBody>
                        </FormRow>

                        {/* Hidden fields so it's included in FormData */}
                        <FormRowHidden>
                            <FormField type="text" title="Adres" name="deliveryAddressLine1" required={true} />
                            <FormField type="text" title="Plaats" name="deliveryCity" required={true} />
                            <FormField type="text" name="deliveryCountry" />
                        </FormRowHidden>

                        <FormRow $spacing="medium">
                            <FormRowItem>
                                <FormField type="tel" title="Telefoonnummer" name="deliveryPhone" required={false}/>
                            </FormRowItem>
                        </FormRow>
                    </ExtraFields>
                }
            </StyledForm>
        </StyledDetails>
    )
}