import styled from 'styled-components';
import {defer, Outlet, useLoaderData, useLocation, useNavigation, useRouteLoaderData} from 'react-router-dom';
import {OrderMenu} from '../components/OrderMenu/OrderMenu';
import {Container} from '../components/Container/Container';
import {OrderOverview} from '../components/OrderOverview/OrderOverview';
import {Button} from '../components/Buttons/Buttons';
import React, {useEffect, useState} from 'react';
import {rootLoaderPromise} from './Root';
import {getCart, getShippingCosts} from '../api/cart';

const StyledOrderLayout = styled.div`
    background-color: var(--color-light);
`;

const Content = styled.main`
    margin-top: 70px;
    padding-top: 50px;
    min-height: calc(100vh - 70px);
    padding-bottom: 100px;
    overflow: hidden;
    
    @media screen and (max-width: 768px) {
        padding-top: 30px;
    }
`;

const Flex = styled.section`
    display: flex;
    gap: 30px;
    align-items: flex-start;
    
    @media screen and (max-width: 768px) {
        gap: 15px;
        flex-direction: column;
        align-items: stretch;
    }
`;

const OutletBox = styled.div`
    flex-grow: 1;
    flex-basis: 390px;

    @media screen and (max-width: 768px) {
        flex-basis: unset;
    }
`;

const MobileActions = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 96;
    display: none;
    justify-content: center;
    gap: 10px;
    padding: 10px 20px 10px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-up);

    ${Button} {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        max-width: unset;
    }

    @media screen and (max-width: 768px) {
        display: flex;
    }
`;

export async function orderLayoutLoader({request}) {
    await rootLoaderPromise;

    let getShippingCostsData;
    let getCartData;
    try {
        [getShippingCostsData, getCartData] = await Promise.all([getShippingCosts(request), getCart(request)]);
    } catch(err) {
        // Catch DOMException for AbortController (this bug is fixed in newer React Router v6.8.0)
    }

    return defer({cartData: getCartData, shippingCostsData: getShippingCostsData});
}

// It should revalidate for correct cart and shipping costs.
export function shouldRevalidateOrderLayout() {
    return true;
}

export default function OrderLayout() {
    const {state} = useNavigation();
    const {checkTokenData} = useRouteLoaderData("root");
    const {cartData} = useLoaderData();

    const [authenticated, setAuthenticated] = useState(!!checkTokenData?.customer?.firstName)

    useEffect(() => {
        setAuthenticated(!!checkTokenData?.customer?.firstName)
    }, [checkTokenData])

    const {pathname} = useLocation();
    const [currentStep, setCurrentStep] = useState(pathname.includes('levering') ? 1 : pathname.includes('jouw-gegevens') ? 2 : 3);

    useEffect(() => {
        if(pathname.includes('levering')) { return setCurrentStep(1); }
        if(pathname.includes('jouw-gegevens')) { return setCurrentStep(2); }
        if(pathname.includes('betaling')) { return setCurrentStep(3); }
    }, [pathname]);

    // Payment Method used to change label of the OrderOverview Button when paymentMethod === 1 (HomeDelivery)
    const [paymentMethod, setPaymentMethod] = useState("61");

    return (
        <StyledOrderLayout>
            <OrderMenu currentStep={currentStep} />
            <Content>
                <Container>
                    <Flex>
                        <OutletBox>
                            <Outlet context={setPaymentMethod} />
                        </OutletBox>
                        <OrderOverview data={cartData} currentStep={currentStep} paymentMethod={paymentMethod} />
                    </Flex>
                </Container>
            </Content>
            { (!pathname.startsWith('/bestellen/jouw-gegevens/adres-bewerken') && !pathname.startsWith('/bestellen/jouw-gegevens/adres-toevoegen')) &&
                <MobileActions>
                    <Button $variant="outline" to={currentStep === 3 ? '/bestellen/jouw-gegevens' : currentStep === 2 ? '/bestellen/levering' : '/winkelmandje'}>Vorige</Button>

                    {currentStep === 1 && <Button as="button" form="deliveryForm" type="submit" $variant="secondary" disabled={state !== 'idle'}>Volgende</Button>}
                    {currentStep === 2 && <Button as="button" form="detailsForm" type="submit" name="intent" value={authenticated ? 'registeredUser' : 'guestUser'} $variant="secondary" disabled={state !== 'idle'}>Volgende</Button>}
                    {currentStep === 3 && <Button as="button" form="paymentForm" type="submit" $variant="secondary" disabled={state !== 'idle'}>{paymentMethod === "1" ? 'Bestelling afronden' : 'Betalen'}</Button>}
                </MobileActions>
            }
        </StyledOrderLayout>
    );
}