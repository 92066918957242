import React, {useEffect, useState} from "react";
import {Await, defer, useLoaderData, useNavigate, useNavigation} from "react-router-dom";
import styled from "styled-components";
import {Container} from "../../components/Container/Container";
import {Headline1} from "../../components/Headlines/Headlines";
import {Body} from "../../components/Texts/Texts";
import {Button} from "../../components/Buttons/Buttons";
import {ReactComponent as EmptyShelf} from '../../assets/images/empty-shelf.svg';
import CartProduct from '../../components/CartProduct/CartProduct';
import OrderSummary from '../../components/OrderSummary/OrderSummary';
import {destroyCart, getCart, postCart, putGiftwrap, updateCart} from '../../api/cart';
import {toast} from 'react-toastify';
import {Toaster} from '../../components/Toaster/Toaster';
import {Spinner} from '../../components/Spinner/Spinner';
import {Seo} from '../../components/Seo/Seo';
import {rootLoaderPromise} from '../../layouts/Root';
import {useProductsInCartContext} from '../../contexts/ProductsInCartContext';
import {sendEcommerceEvent} from '../../events/dataLayer';
import {CheckoutButton} from '../../components/CheckoutButton/CheckoutButton';

const Wrapper = styled.div`
    margin: 40px 0 80px 0;
`;

const CartHeader = styled.div`
    margin-bottom: 40px;
`;

const NoProducts = styled.div`
    margin-top: 20px;
`;

const OrderBox = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 30px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const ContinueDesktop = styled(Button)`
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const MobileActions = styled.div`
    @media screen and (min-width: 769px) {
        display: none;
    }
    
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 97;
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px 20px 10px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-up);
    
    ${Button}{
        max-width: unset;
        display: flex;
        align-self: center;
        justify-content: center;
    }
`;

const CartWrapper = styled.div`
    flex-shrink: 1;
    flex-basis: 810px;
    
    @media screen and (max-width: 768px) {
        flex-basis: unset;
    }
`;

const CartProducts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 30px;
`;

const SpinnerWrapper = styled.div`
    padding: 0 0 64px;
`;

export async function cartOverviewAction({request}) {
    let formData = await request.formData();
    const isbn = formData.get("isbn");
    const quantity = formData.get("quantity");
    const id = formData.get("id");
    const giftwrap = JSON.parse(formData.get("giftwrap"));
    const eanf = formData.get("eanf");
    const intent = formData.get("intent");

    // The add giftwrap action is also a PUT call so extracted with an `intent`
    if(intent === "add-giftwrap" || intent === "remove-giftwrap") {
        const productObj = {
            "giftwrap": intent === "add-giftwrap" ? 128 : 0,
            "quantity": parseInt(quantity),
            ...(eanf && {"eanf": JSON.parse(eanf)}),
        }

        try {
            await putGiftwrap(request, id, productObj);

            if(intent === "remove-giftwrap") return toast(<Toaster type='success' title='Gelukt!' message='Het artikel zal niet verpakt worden als een cadeautje.' $onLight/>);

            return toast(<Toaster type='success' title='Gelukt!' message='Het artikel zal verpakt worden als een cadeautje.' $onLight/>);
        } catch(err) {
            return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
        }
    }

    switch(request.method) {
        case "POST": {
            const productObj = {
                "quantity": 1,
                ...(giftwrap && {"giftwrap": parseInt(giftwrap)}),
                ...(eanf && {"eanf": JSON.parse(eanf)}),
            }

            try {
                await postCart(request, isbn, productObj);
                return toast(<Toaster type='success' title='Gelukt!' message='Het artikel is toegevoegd aan je winkelmandje.' $onLight/>);
            } catch(err) {
                switch (err.status) {
                    case 409:
                        return toast(<Toaster type='warning' title='Let op' message='Het product zit al in je winkelmandje.' $onLight/>);
                    default:
                        return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
                }
            }
        }
        case "DELETE": {
            const productObj = {
                ...(giftwrap && {"giftwrap": parseInt(giftwrap)}),
                ...(eanf && {"eanf": JSON.parse(eanf)})
            }

            try {
                const destroyCartData = await destroyCart(request, isbn, productObj);
                toast(<Toaster type='success' title='Gelukt!' message='Het artikel is verwijderd uit je winkelmandje.' $onLight/>);
                return destroyCartData;
            } catch(err) {
                return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
            }
        }
        case "PUT": {
            const productObj = {
                "quantity": parseInt(quantity),
                ...(giftwrap && {"giftwrap": parseInt(giftwrap)}),
                ...(eanf && {"eanf": JSON.parse(eanf)})
            }

            try {
                await updateCart(request, isbn, productObj);
                return toast(<Toaster type='success' title='Gelukt!' message={`Het artikel zit nu ${quantity} keer in je winkelmandje.`} $onLight/>);
            } catch(err) {
                return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
            }
        }
        default: {
            throw new Response("Method not allowed", { status: 405})
        }
    }
}

export async function cartLoader({request}) {
    await rootLoaderPromise;

    let getCartData;
    try {
        getCartData = await getCart(request);
    } catch (err) {
        throw new Response("Page is not found", { status: 404 });
    }

    return defer({cartData: getCartData});
}

export default function CartOverview() {
    const {cartData} = useLoaderData();
    const {addFetchedCart} = useProductsInCartContext();
    const {state} = useNavigation();
    const navigate = useNavigate();
    const [productAvailabilities, setProductAvailabilities] = useState({});
    const allResolved = Object.keys(productAvailabilities).length >= cartData?.products?.length;
    const hasUnavailableProduct = Object.values(productAvailabilities).includes("unavailable");

    // Make sure productAvailabilities only contains products that are in the cart (so when deleting from cart, also delete from productAvailabilities)
    useEffect(() => {
        if (cartData?.products) {
            const validKeys = cartData?.products?.map(product => (
                product?.secondhand ? `${product?.isbn}/${product?.eanf}` : product?.isbn
            ));

            setProductAvailabilities(prev => {
                return Object.keys(prev)
                    .filter(key => validKeys.includes(key))
                    .reduce((acc, key) => {
                        acc[key] = prev[key];
                        return acc;
                    }, {});
            });
        }
    }, [cartData?.products]);

    // Set the isbnsWithGiftwrapInCart each time cartData changes
    useEffect(() => {
        if(cartData) {
            addFetchedCart(cartData);
        }
        // eslint-disable-next-line
    }, [cartData])

    useEffect(() => {
        if(cartData?.products?.length >= 1) {
            sendEcommerceEvent('view_cart', cartData?.products);
        }
        // eslint-disable-next-line
    }, [])

    const handleBeginCheckoutMobile = (data) => {
        if(data?.products && state === "idle") sendEcommerceEvent('begin_checkout', data.products)

        if (hasUnavailableProduct) {
            return toast(<Toaster type='danger' title='Mislukt!' message='Het gekozen boek is helaas uitverkocht. Verwijder het boek uit je winkelmandje om verder te gaan.' $onLight/>);
        }

        navigate("/bestellen/levering");
    }

    return (
        <Container>
            <Seo metaTitle="Winkelmandje" />

            <Wrapper>
                <CartHeader>
                    <Headline1>Winkelmandje</Headline1>
                </CartHeader>

                <React.Suspense fallback={<SpinnerWrapper><Spinner /></SpinnerWrapper>}>
                    <Await resolve={cartData} errorElement={<p>Er is iets fout gegaan</p>}>
                        {(cart) => (
                            <>
                                {cart?.products?.length > 0
                                    ?
                                    <>
                                        <OrderBox>
                                            <CartWrapper>
                                                <CartProducts>
                                                    {cart?.products?.map((product, index) => (
                                                        <CartProduct
                                                            key={`${index}-${product?.id}`}
                                                            data={product}
                                                            setProductAvailabilities={setProductAvailabilities}
                                                        />
                                                    ))}
                                                </CartProducts>
                                                <ContinueDesktop to="/boeken" $variant="outline">Verder winkelen</ContinueDesktop>
                                            </CartWrapper>
                                            <OrderSummary
                                                data={cart}
                                                allResolved={allResolved}
                                                hasUnavailableProduct={hasUnavailableProduct}
                                            />
                                        </OrderBox>
                                        <MobileActions>
                                            <Button to="/boeken" $variant="outline">Verder winkelen</Button>
                                            <CheckoutButton
                                                disabled={state !== 'idle'}
                                                loading={!allResolved}
                                                onClick={() => handleBeginCheckoutMobile(cart)}
                                            />
                                        </MobileActions>
                                    </>
                                    :
                                    <>
                                        <NoProducts>
                                            <EmptyShelf/>
                                            <Body>Je hebt op dit moment geen artikelen in je winkelmandje.</Body>
                                        </NoProducts>
                                        <Button to="/boeken" $variant="secondary">Verder winkelen</Button>
                                    </>
                                }
                            </>
                        )}
                    </Await>
                </React.Suspense>

            </Wrapper>
        </Container>
    )
}