import Cookies from "js-cookie";

// Get cart items
export const getCart = async (request) => {
    const getCartRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/management`, {
        signal: request.signal,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!getCartRes.ok) {
        throw new Response("Response is not OK", {status: getCartRes.status});
    }

    const getCartData = await getCartRes.json();

    return getCartData;
}

// Add product to cart
export const postCart = async (request, isbn, productObj) => {
    const postCartRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/products/${isbn}`, {
        // signal: request.signal,
        method: 'POST',
        body: JSON.stringify(productObj),
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!postCartRes.ok) {
        throw new Response("Response is not OK", {status: postCartRes.status});
    }

    const postCartData = await postCartRes.json();

    return postCartData;
}

// Delete item from cart
export const destroyCart = async (request, isbn, productObj) => {
    const destroyCartRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/products/${isbn}`, {
        // signal: request.signal,
        method: 'DELETE',
        body: JSON.stringify(productObj),
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!destroyCartRes.ok) {
        throw new Response("Response is not OK", {status: destroyCartRes.status});
    }

    const destroyCartData = await destroyCartRes.json();

    return destroyCartData;
}

// Update quantity in cart
export const updateCart = async (request, isbn, productObj) => {
    const updateCartRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/products/${isbn}`, {
        // signal: request.signal,
        method: 'PUT',
        body: JSON.stringify(productObj),
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!updateCartRes.ok) {
        throw new Response("Response is not OK", {status: updateCartRes.status});
    }

    const updateCartData = await updateCartRes.json();

    return updateCartData;
}

// Put giftwrap to cart line
export const putGiftwrap = async (request, id, productObj) => {
    const putGiftwrapRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/management/giftwrap/${id}`, {
        // signal: request.signal,
        method: 'PUT',
        body: JSON.stringify(productObj),
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!putGiftwrapRes.ok) {
        throw new Response("Response is not OK", {status: putGiftwrapRes.status});
    }

    const putGiftwrapData = await putGiftwrapRes.json();

    return putGiftwrapData;
}

// Get delivery types
export const getDeliveryTypes = async (request) => {
    const getDeliveryTypesRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/informations/delivery`, {
        signal: request.signal,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!getDeliveryTypesRes.ok) {
        throw new Response("Response is not OK", {status: getDeliveryTypesRes.status});
    }

    const getDeliveryTypesData = await getDeliveryTypesRes.json();

    return getDeliveryTypesData;
}

// Get payment methods
export const getPaymentMethods = async (request) => {
    const getPaymentMethodsRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/informations/payment`, {
        signal: request.signal,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!getPaymentMethodsRes.ok) {
        throw new Response("Response is not OK", {status: getPaymentMethodsRes.status});
    }

    const getPaymentMethodsData = await getPaymentMethodsRes.json();

    return getPaymentMethodsData;
}

// Set cart information
export const setCartInformations = async (request, informationsObj) => {
    const setCartInformationsRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/informations`, {
        signal: request.signal,
        method: 'PUT',
        body: JSON.stringify(informationsObj),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!setCartInformationsRes.ok) {
        throw new Response("Response is not OK", {status: setCartInformationsRes.status});
    }

    const setCartInformationsData = await setCartInformationsRes.json();

    return setCartInformationsData;
}

// Confirm cart
export const confirmCart = async (request) => {
    const confirmCartRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/management/confirm`, {
        signal: request.signal,
        method: 'PUT',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'token': Cookies.get('titelive_webshop'),
        },
    });

    if (!confirmCartRes.ok) {
        throw new Response("Response is not OK", {status: confirmCartRes.status});
    }

    const confirmCartData = await confirmCartRes.json();

    return confirmCartData;
}

// Get shipping costs simulation
export const getShippingCosts = async (request) => {
    const getShippingCostsRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/informations/shipping?country=2`, {
        signal: request.signal,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!getShippingCostsRes.ok) {
        throw new Response("Response is not OK", {status: getShippingCostsRes.status});
    }

    const getShippingCostsData = await getShippingCostsRes.json();

    return getShippingCostsData;
}

// Check a second hand product with isbn and eanf if the product is reserved
export const checkSecondHandProduct = async (request, isbn, eanf) => {
    const checkSecondHandProductRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/product-info-secondhand?isbn=${isbn}&eanf=${eanf}`, {
        signal: request.signal,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!checkSecondHandProductRes.ok) {
        throw new Response("Response is not OK", {status: checkSecondHandProductRes.status});
    }

    const checkSecondHandProductData = await checkSecondHandProductRes.json();

    return checkSecondHandProductData;
}