import styled from 'styled-components';
import {Button} from '../Buttons/Buttons';
import {Spinner} from '../Spinner/Spinner';
import React from 'react';

const StyledCheckoutButton = styled(Button)`
    position: relative;

    &:disabled {
        &:hover {
            background-color: var(--color-secondary);
        }
    }
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const CheckoutButtonSpinnerWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CheckoutButton = ({disabled, loading, onClick, ...props}) => {
    return (
        <StyledCheckoutButton
            $variant='secondary'
            as="button"
            disabled={disabled || loading}
            onClick={onClick}
            {...props}
        >
            {loading ? (
                <CheckoutButtonSpinnerWrapper>
                    <Spinner $small $white />
                </CheckoutButtonSpinnerWrapper>
            ) : (
                "Bestellen"
            )}
        </StyledCheckoutButton>
    );
}