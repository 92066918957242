import {Headline3, Headline4} from "../Headlines/Headlines";
import styled from "styled-components";
import {Subtitle2} from "../Subtitles/Subtitles";
import {Body, bodyTextStyles} from "../Texts/Texts";
import {IconButton} from "../Buttons/Buttons";
import {ReactComponent as Favorite} from "../../assets/icons/favorite-outline.svg";
import {ReactComponent as Bin} from "../../assets/icons/bin.svg";
import {Link, useFetcher} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import FormField from '../Fields/FormField';
import {useFetchData} from '../../hooks/useFetchData';
import {ProductAvailability, ProductAvailabilityFallback} from '../ProductAvailability/ProductAvailability';
import check from '../../assets/icons/check.svg';
import {ReactComponent as Gift} from '../../assets/icons/gift.svg';
import {useProductsInCartContext} from '../../contexts/ProductsInCartContext';
import {ProductFlag} from '../ProductFlag/ProductFlag';
import {sendEcommerceEvent} from '../../events/dataLayer';
import {CART_LIST} from '../../events/constants';
import qs from 'qs';

export const ProductBox = styled.div`
    display: flex;
    gap: 33px;
    width: 100%;
    border-bottom: 1px solid var(--color-primary-10);
    padding-bottom: 32px;

    @media screen and (max-width: 768px) {
        gap: 12px;
    }
`;

export const ImageWrapper = styled(Link)`
    max-width: ${props => props.$inSideDrawer ? '124px' : '148px'};
    max-height: 216px;
    width: 100%;

    @media screen and (max-width: 768px) {
        flex: 1;
    }
`;

export const MainImageWrapper = styled.div`
    position: relative;
`;

export const ProductImage = styled.img`
    max-width: ${props => props.$inSideDrawer ? '114px' : '135px'};
    max-height: 216px;
    width: 100%;
    object-fit: contain;
    object-position: bottom;
    display: block;
`;

export const BookShelf = styled.hr`
    border: 1px solid var(--color-primary);
    margin: 0;
    width: 100%;
`;

export const ProductSpecs = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    
    @media screen and (max-width: 768px) {
        flex: 5;
    }
`;

export const TitlePrice = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 30px;
`;

export const ProductTitle = styled(Link)`
    max-width: 420px;
    text-decoration: none;
    color: var(--color-primary);

    &:hover {
        text-decoration: underline;
    }

    ${Headline3} {
        ${props => props.$inSideDrawer && bodyTextStyles};
        
        @media screen and (max-width: 768px) {
            ${bodyTextStyles};
        }
    }
`;

export const ProductForm = styled(Body)`
    margin: 0 0 16px;
`;

export const PriceBox = styled.div`
    display: flex;
    justify-content: right;
    flex-shrink: 0;
`;

const Price = styled(Headline4)`
    ${props => props.$inSideDrawer && bodyTextStyles};
    ${props => (props.$inSideDrawer) && 'color: var(--color-secondary);'};
`;

const Actions = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${props => props.$inSideDrawer ? 0 : '14px'};
    margin-top: auto;
    gap: 14px;
`;

const ManageBox = styled.div`
    display: flex;
`;

const IconOnly = styled(IconButton)`
    box-shadow: unset;
`;

const Quantity = styled.form`
    width: 73px;
    height: 50px;
`;

const SecondHandQuantity = styled.div`
    ${bodyTextStyles};
    width: 73px;
    height: 50px;
    background-color: var(--color-white);
    padding: 12px;
    border: 1px solid var(--color-primary-10);
`;

const Label = styled(Body).attrs({
    as: "label",
})`
    cursor: pointer;
    line-height: 1.35;
`;

const Checkbox = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    
    &:not(:last-of-type) {
        margin-bottom: 15px;
    }
`;

const CheckboxInput = styled.input`
    flex-shrink: 0;
    width: 17px;
    height: 17px;
    background-color: var(--color-primary-10);
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0 12px 0 0;

    &:checked {
        border: 0;
        background-image: url(${check});
        background-color: var(--color-tertiary);
        background-size: 75% 65%;
    }
    
    &:checked + ${Label} {
        font-weight: 700;
    }
`;

const StyledGift = styled(Gift)`
    flex-shrink: 0;
    width: 34px;
    height: 34px;
`;

const Announcement = styled(Body)`
    padding: 14px 14px 14px 20px;
    border-left: 5px solid var(--color-accent);
    background-color: var(--color-light);
`;

export default function CartProduct({data, inSideDrawer = false, close, onDelete = null, setProductAvailabilities = null}) {
    const productAvailabilityQueryString = qs.stringify({isbn: data?.isbn, ...(data?.secondhand) ? { eanf: data?.eanf } : {}})
    const [productAvailabilityData, productAvailabilityError] = useFetchData(!inSideDrawer ? `product-detail-shops${productAvailabilityQueryString ? `?${productAvailabilityQueryString}` : ""}` : null, true);
    const {removeFromArray} = useProductsInCartContext();
    const fetcher = useFetcher();
    let postBusy = (fetcher.formMethod === "post" && fetcher.state !== 'idle');
    let deleteBusy = (fetcher.formMethod === "delete" && fetcher.state !== 'idle');
    let putBusy = (fetcher.formMethod === "put" && fetcher.state !== 'idle');
    let disabled = postBusy || deleteBusy || putBusy;

    const { register, formState: { errors }, setValue, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            quantity: data?.quantity ?? 1,
            isbn: data?.isbn,
            id: data?.id,
        }
    });

    // Update quantity value if loader data changes
    useEffect(() => {
        setValue('quantity', data?.quantity ?? 1)
        // eslint-disable-next-line
    }, [data])

    FormField.defaultProps = { errors: errors, register: register }

    const [quantity, setQuantity] = useState(data?.quantity ?? 1);
    const handleQuantity = async (values) => {
        const formData = new FormData();
        formData.append("isbn", data?.isbn);
        formData.append("quantity", values?.quantity);
        formData.append("giftwrap", data?.giftwrap);
        formData.append("eanf", data?.eanf);
        fetcher.submit(formData, { method: 'PUT', action: '/winkelmandje'});

        const quantityChange = values?.quantity - quantity;
        setQuantity(values?.quantity);
        if(quantityChange > 0) sendEcommerceEvent('add_to_cart', [data], {}, quantityChange);
        if(quantityChange < 0) sendEcommerceEvent('remove_from_cart', [data], {}, Math.abs(quantityChange));
    }

    const handleDeleteButton = async () => {
        const formData = new FormData();
        formData.append("isbn", data?.isbn);
        formData.append("giftwrap", data?.giftwrap);
        removeFromArray(data?.isbn); // explicitly remove from isbnsWithGiftwrapInCart array, because you can also delete from sideDrawer and there it doesn't revalidate cart management call
        formData.append("eanf", data?.eanf);
        fetcher.submit(formData, { method: 'DELETE', action: '/winkelmandje'})

        sendEcommerceEvent('remove_from_cart', [data], {}, data?.quantity);
    }

    const handleWishlistButton = async () => {
        const formData = new FormData();
        formData.append("isbn", data?.isbn);
        fetcher.submit(formData, { method: 'POST', action: '/verlanglijstje'})

        sendEcommerceEvent('add_to_wishlist', [data]);
    }

    const handleGiftwrapButton = async () => {
        const formData = new FormData();
        formData.append("intent", data?.giftwrap ? "remove-giftwrap" : "add-giftwrap");
        formData.append("quantity", data?.quantity);
        formData.append("id", data?.id);
        formData.append("eanf", data?.eanf);
        fetcher.submit(formData, { method: 'PUT', action: '/winkelmandje'})
    }

    useEffect(() => {
        if(fetcher?.data?.products && fetcher.state === 'idle' && inSideDrawer) {
            onDelete(fetcher.data);
        }
        // eslint-disable-next-line
    }, [fetcher.state])

    const handleCartProductClick = () => {
        if(close) close();

        if(data) {
            sendEcommerceEvent('select_item', [data], {
                item: {
                    item_list_name: CART_LIST.name,
                    item_list_id: CART_LIST.id,
                }
            });
        }
    }

    return (
        <ProductBox>
            <ImageWrapper to={`/${data?.url}`} onClick={handleCartProductClick} $inSideDrawer={inSideDrawer}>
                <MainImageWrapper>
                    <ProductImage src={data?.image} alt={data?.title} $inSideDrawer={inSideDrawer} />
                    {(data?.secondhand || data?.eBook) &&
                        <ProductFlag flag={data?.secondhand ? "secondHand" : "ebook"} location="CartProduct" />
                    }
                </MainImageWrapper>
                <BookShelf/>
            </ImageWrapper>

            <ProductSpecs>
                <Subtitle2>{data?.author}</Subtitle2>

                <TitlePrice>
                    <ProductTitle to={`/${data?.url}`} onClick={handleCartProductClick} $inSideDrawer={inSideDrawer} $bold>
                        <Headline3>{data?.title}</Headline3>
                    </ProductTitle>
                    <PriceBox $inSideDrawer={inSideDrawer}>
                        <Price $bold $inSideDrawer={inSideDrawer}>{data?.price ?? "Fout"}</Price>
                    </PriceBox>
                </TitlePrice>

                <ProductForm>{data?.secondhand ? "Tweedehands" : data?.productForm}</ProductForm>

                <Actions $inSideDrawer={inSideDrawer}>
                    <ManageBox>
                        {data?.secondhand ? (
                            <SecondHandQuantity>{data?.quantity}</SecondHandQuantity>
                        ) : (
                            <Quantity id={data?.isbn} onChange={handleSubmit(handleQuantity)}>
                                <FormField
                                    type="select"
                                    name="quantity"
                                    disabled={disabled}
                                    required={true}
                                    options={Array.from({length: data?.quantity > 10 ? data?.quantity : 10}, (_, i) => ({
                                        id: (i + 1),
                                        value: (i + 1),
                                        title: (i + 1).toString()
                                    }))}
                                />
                            </Quantity>
                        )}

                        <IconOnly as="button" onClick={handleWishlistButton} disabled={disabled} $variant='outline'><Favorite/></IconOnly>

                        <IconOnly as="button" onClick={handleDeleteButton} disabled={disabled} $variant='outline'><Bin/></IconOnly>
                    </ManageBox>

                    {(!inSideDrawer && !data?.eBook) && (
                        <Checkbox htmlFor={`giftwrap-${data?.giftwrap}-${data?.eanf}-${data?.isbn}`}>
                            <CheckboxInput id={`giftwrap-${data?.giftwrap}-${data?.eanf}-${data?.isbn}`} type="checkbox" checked={data?.giftwrap ?? false} name="giftwrap" onChange={handleSubmit(handleGiftwrapButton)} disabled={disabled} />
                            <Label htmlFor={`giftwrap-${data?.giftwrap}-${data?.eanf}-${data?.isbn}`}>Het is een cadeautje</Label>
                            <StyledGift />
                        </Checkbox>
                    )}
                </Actions>

                {(!inSideDrawer && (data?.giftwrap ?? false)) && (
                    <Announcement>Houd er rekening mee dat cadeaus één werkdag extra levertijd hebben, omdat onze medewerkers ze met zorg en liefde persoonlijk voor je inpakken!</Announcement>
                )}

                {!inSideDrawer && (
                    productAvailabilityData ? (
                        <ProductAvailability
                            data={productAvailabilityData}
                            availabilityKey={data?.secondhand ? `${data?.isbn}/${data?.eanf}` : data?.isbn}
                            onResolve={setProductAvailabilities ? (key, value) =>
                                setProductAvailabilities(prev => ({
                                    ...prev,
                                    [key]: value
                                })) : undefined}
                        />
                    ) : productAvailabilityError ? null : (
                        <ProductAvailabilityFallback />
                    )
                )}
            </ProductSpecs>
        </ProductBox>
    )
}