import React, {useEffect} from 'react';
import {ReactComponent as ChevronIcon} from '../../../assets/icons/chevron-left.svg';
import {AddressForm} from '../../../components/AddressForm/AddressForm';
import styled from 'styled-components';
import {Button} from '../../../components/Buttons/Buttons';
import {defer, useLoaderData, useSubmit} from 'react-router-dom';
import FormField from '../../../components/Fields/FormField';
import {useForm} from 'react-hook-form';
import {useAddressFromPostalCode} from '../../../hooks/useAddressFromPostalCode';
import {getAddress} from '../../../api/addresses';
import {rootLoaderPromise} from '../../../layouts/Root';
import {useDebouncedCallback} from 'use-debounce';

const StyledDetails = styled.section``;

const BackButton = styled(Button)`
    max-width: 116px;
`;

export async function editAddressLoader({request, params}) {
    await rootLoaderPromise;

    let getAddressData;
    try {
        getAddressData = await getAddress(request, params?.id);
    } catch (err) {
        throw new Response("Page is not found", { status: 404 });
    }

    return defer({addressData: getAddressData});
}

export default function EditAddress() {
    const {addressData} = useLoaderData();

    useEffect(()  => {
        document.body.style.backgroundColor = '#FAFAFA';
        return () => {
            document.body.style.removeProperty('background-color');
        };
    });

    const { register, formState: { errors }, handleSubmit, getValues, setValue } = useForm({
        defaultValues: {
            id: addressData?.id,
            salutation: addressData?.salutation,
            firstName: addressData?.firstName,
            prefix: addressData?.prefix ?? "",
            lastName: addressData?.lastName,
            postcode: addressData?.postcode,
            number: addressData?.number,
            postbus: addressData?.postbus ?? "",
            addressLine1: addressData?.addressLine1,
            type: addressData?.type,
            city: addressData?.city,
            country: addressData?.country,
            phone: addressData?.phone ?? "",
            intent: "editAddress",
        }
    });
    FormField.defaultProps = {errors: errors, register: register};

    const submit = useSubmit();

    const onSubmit = (data) => {
        submit(data, { method: 'put', action: '/bestellen/jouw-gegevens' })
    };

    let defaultAddress = `${addressData?.addressLine1} ${addressData?.number}${addressData?.postbus ?? ""}, ${addressData?.city}`;
    const {displayAddressFromPostalCode, handleAddress} = useAddressFromPostalCode(getValues, setValue, defaultAddress);
    const debouncedHandleAddress = useDebouncedCallback(handleAddress, 400);

    return (
        <StyledDetails>
            <BackButton to="/bestellen/jouw-gegevens" $icon $variant="outline"><ChevronIcon /> Terug</BackButton>
            <AddressForm onSubmit={handleSubmit(onSubmit)} handleAddress={debouncedHandleAddress} displayAddressFromPostalCode={displayAddressFromPostalCode} breakpoint={1024} register={register} errors={errors} intent="editAddress" />
        </StyledDetails>
    );
}