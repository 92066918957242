import {Headline3} from '../../../components/Headlines/Headlines';
import FormField from '../../../components/Fields/FormField';
import styled from 'styled-components';
import {useForm} from 'react-hook-form';
import {ReactComponent as LogoIdeal} from '../../../assets/paymentOptions/ideal.svg';
import {ReactComponent as LogoMastercard} from '../../../assets/paymentOptions/mastercard.svg';
import {ReactComponent as LogoVisa} from '../../../assets/paymentOptions/visa.svg';
import {ReactComponent as LogoDeKler} from '../../../assets/paymentOptions/dekler.svg';
import React, {useEffect} from 'react';
import {checkSecondHandProduct, confirmCart, getPaymentMethods, setCartInformations} from '../../../api/cart';
import {toast} from 'react-toastify';
import {Toaster} from '../../../components/Toaster/Toaster';
import {Await, defer, redirect, useLoaderData, useOutletContext, useRouteLoaderData, useSubmit} from 'react-router-dom';
import {getPaymentLink} from '../../../api/payment';
import {Seo} from '../../../components/Seo/Seo';
import {Body} from '../../../components/Texts/Texts';
import {Radio} from '../../../components/Fields/RadioField';
import {Spinner} from '../../../components/Spinner/Spinner';
import {rootLoaderPromise} from '../../../layouts/Root';
import {sendEcommerceEvent} from '../../../events/dataLayer';

const StyledPayment = styled.section``;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column-reverse;
`;

const PaymentMethod = styled.label`
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    padding: 30px 25px;
    margin-top: 20px;
    
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    
    @media screen and (max-width: 768px) {
        margin-top: 15px;
    }
`;

const PaymentMethodOption = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

const PaymentOptionsBox = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-left: 27px;
`;

const PaymentOption = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-light);
    width: 50px;
    height: 50px;
    border-radius: 50%;
`;

const SpinnerWrapper = styled.div`
    padding: 20px 0 0 27px;
`;

export async function paymentAction({request}) {
    let formData = await request.formData();

    const secondHandProducts = JSON.parse(formData.get("secondHandProducts"));

    if (secondHandProducts?.length > 0) {
        try {
            const checkResults = await Promise.all(
                secondHandProducts.map(product => checkSecondHandProduct(request, product.isbn, product.eanf))
            );

            // Check if any of the secondHandProducts is reserved
            if (checkResults.some(result => result?.reserved === true)) {
                return toast(<Toaster type="danger" title="Mislukt!" message="Het gekozen boek is helaas uitverkocht. Verwijder het boek uit je winkelmandje om verder te gaan." $onLight/>);
            }

        } catch(err) {
            // On error, no-op and continue in order flow
        }
    }

    // Pay with MultiSafePay
    if(parseInt(formData.get("paymentMethod")) === 61) {
        const informationsObj = {
            "paymentMethod": 61,
            ...(formData.get("shop") && {
                "shop": parseInt(formData.get("shop")),
            }),
        }

        // Set CartInformation
        try {
            const cartInformationsData = await setCartInformations(request, informationsObj);

            // Confirm cart
            try {
                const confirmCartData = await confirmCart(request)

                // Get payment link
                try {
                    sendEcommerceEvent('purchase', cartInformationsData?.products, {
                        ecommerce: {
                            currency: "EUR",
                            value: cartInformationsData.originalTotalPrice ?? "0",
                            shipping: cartInformationsData.originalShipping ?? "0",
                            affiliation: "De Kler",
                            transaction_id: confirmCartData?.orderID?.toString(),
                        }
                    });

                    const getPaymentLinkData = await getPaymentLink(request, confirmCartData?.orderID)

                    return redirect(getPaymentLinkData?.msp_Link_Redirect)
                } catch(err) {
                    return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
                }

            } catch(err) {
                return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
            }
        } catch(err) {
            return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
        }
    }

    // Pay in shop
    if(parseInt(formData.get("paymentMethod")) === 1) {
        const informationsObj = {
            "paymentMethod": 1,
            ...(formData.get("shop") && {
                "shop": parseInt(formData.get("shop")),
            })
        }

        // Set CartInformation
        try {
            const cartInformationsData = await setCartInformations(request, informationsObj);

            // Confirm cart
            try {
                const confirmCartData = await confirmCart(request);

                sendEcommerceEvent('purchase', cartInformationsData?.products, {
                    ecommerce: {
                        currency: "EUR",
                        value: cartInformationsData.originalTotalPrice ?? "0",
                        shipping: cartInformationsData.originalShipping ?? "0",
                        affiliation: "De Kler",
                        transaction_id: confirmCartData?.orderID?.toString(),
                    }
                });

                return redirect('/bestelling-gelukt')
            } catch(err) {
                return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
            }
        } catch(err) {
            return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
        }
    }

    return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
}

export async function paymentLoader({request}) {
    await rootLoaderPromise;

    let getPaymentMethodsData;
    try {
        getPaymentMethodsData = await getPaymentMethods(request);
    } catch (err) {
        throw new Response("Page is not found", { status: 404 });
    }

    return defer({paymentMethodsData: getPaymentMethodsData});
}

export default function Payment() {
    const {cartData} = useRouteLoaderData("orderLayout");
    const {paymentMethodsData} = useLoaderData();
    const secondHandProductsToCheck = cartData?.products?.filter(product => product.secondhand === true)?.map(product => ({
        isbn: product.isbn,
        eanf: product.eanf,
    }));

    const { register, formState: { errors }, handleSubmit, watch } = useForm({
        defaultValues: {
            paymentMethod: cartData?.paymentMethod?.toString() ?? "61",
            shop: cartData?.shop ?? "",
            secondHandProducts: JSON.stringify(secondHandProductsToCheck),
        }
    });

    const submit = useSubmit();

    const onSubmit = (data) => {
        submit(data, { method: 'put', action: '/bestellen/betaling' })
        sendEcommerceEvent('add_payment_info', cartData?.products, {
            ecommerce: {
                payment_type: data?.paymentMethod?.toString() === "61" ? "Via iDEAL, Creditcard, boekenbon of De Kler cadeaukaart" : data?.paymentMethod?.toString() === "1" ? "Betalen in de winkel" : "",
            }
        })
    };

    FormField.defaultProps = {errors: errors, register: register}

    const watchPaymentMethod = watch("paymentMethod");
    const setPaymentMethod = useOutletContext();

    useEffect(() => {
        setPaymentMethod(watchPaymentMethod)
    }, [setPaymentMethod, watchPaymentMethod])

    return (
        <StyledPayment>
            <Seo metaTitle="Betaling" />

            <Headline3>Betaling</Headline3>

            <React.Suspense fallback={<SpinnerWrapper><Spinner/></SpinnerWrapper>}>
                <Await resolve={paymentMethodsData} errorElement={<Body>Er is iets fout gegaan, probeer het opnieuw.</Body>}>
                    {(paymentMethods) => (
                        <StyledForm id="paymentForm" onSubmit={handleSubmit(onSubmit)}>
                            {paymentMethods?.map(item => (
                                <PaymentMethod key={item.id} htmlFor={item.id}>
                                    <PaymentMethodOption>
                                        <Radio id={item.id} type="radio" value={item.id} {...register('paymentMethod')} />
                                        <Body as="label" htmlFor={item.id}>{item.id === 61 ? "Via iDEAL, Creditcard, boekenbon of De Kler cadeaukaart" : item.id === 1 ? "Betalen in de winkel" : ""}</Body>
                                    </PaymentMethodOption>

                                    {item.id === 61 &&
                                        <PaymentOptionsBox>
                                            <PaymentOption><LogoIdeal/></PaymentOption>
                                            <PaymentOption><LogoMastercard/></PaymentOption>
                                            <PaymentOption><LogoVisa/></PaymentOption>
                                            <PaymentOption><LogoDeKler/></PaymentOption>
                                        </PaymentOptionsBox>
                                    }
                                </PaymentMethod>
                            ))}
                        </StyledForm>
                    )}
                </Await>
            </React.Suspense>
        </StyledPayment>
    );
}